var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        "header-class": "header-class-modal-doc-package",
        title: _vm.title,
        size: "lg",
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { hidden: _vm.emitEventClose },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px mr-3",
                      staticStyle: { "margin-top": "5px" },
                      attrs: {
                        size: "md",
                        variant: "custom-outline-gray",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      attrs: {
                        size: "md",
                        variant: "primary",
                        disabled: _vm.watingSubmitForm,
                        block: "",
                      },
                      on: { click: _vm.dispatchEvent },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("b-spinner", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.watingSubmitForm,
                                expression: "watingSubmitForm",
                              },
                            ],
                            attrs: { small: "" },
                          }),
                          _c(
                            "div",
                            {
                              class: `${_vm.watingSubmitForm ? "pl-2" : ""}`,
                              staticStyle: { "margin-top": "1px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(2, "Save")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container-layout" },
        [
          _c("tax-form", {
            ref: "form",
            attrs: {
              action: _vm.action,
              "display-add-btn": false,
              item: _vm.tax,
            },
            on: {
              "finance-tax-form:waiting": _vm.onTaxFormLoaded,
              "finance-tax-form:close-modal": _vm.onTaxFormForceClosed,
              "finance-tax-form:reload": _vm.onTaxFormReloaded,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }