var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      {
        staticClass: "px-0",
        class: {
          "container-layout": _vm.$screen.width > 576,
          "container-mobile": _vm.$screen.width <= 576,
        },
      },
      [
        _c(
          "b-form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitForm.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "card-border-blue-light",
                        attrs: { "no-body": "" },
                      },
                      [
                        _c(
                          "b-row",
                          {
                            staticClass:
                              "position-sticky back-with-title d-flex align-items-center pb-1 pl-0",
                          },
                          [
                            _c("b-col", { attrs: { cols: "6" } }, [
                              _c(
                                "h2",
                                { staticStyle: { "font-size": "1.5rem" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(1, "Finance Configuration")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "b-col",
                              {
                                staticClass: "d-flex justify-content-end",
                                attrs: { cols: "6" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "w-138-px d-flex justify-content-center align-items-center",
                                    staticStyle: { "margin-top": "-8px" },
                                    attrs: {
                                      type: "submit",
                                      size: "md",
                                      variant: "secondary",
                                      block: "",
                                      disabled: _vm.watingSubmitForm,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center align-items-center",
                                      },
                                      [
                                        _c("b-spinner", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.watingSubmitForm,
                                              expression: "watingSubmitForm",
                                            },
                                          ],
                                          attrs: { small: "" },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pl-2",
                                            staticStyle: {
                                              "margin-top": "1px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.FormMSG(2, "Save"))
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c("b-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "card-collapsible px-0 mt-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "card-collapsible px-0 mt-3",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleClickHeader(
                                                "company-details"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "title w-90" },
                                            [
                                              _c(
                                                _vm.getLucideIcon("Building"),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.ALARM_PLUS
                                                        .color,
                                                    size: 22,
                                                  },
                                                }
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fz-1-35 ml-3" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        8,
                                                        "Company Details"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "cursor-pointer d-flex justify-content-end w-10",
                                            },
                                            [
                                              !_vm.configAccordion[
                                                "company-details"
                                              ].isVisible
                                                ? _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.CHEVRON_DOWN
                                                        .name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.CHEVRON_DOWN
                                                            .color,
                                                        size: 22,
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                              _vm.configAccordion[
                                                "company-details"
                                              ].isVisible
                                                ? _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.CHEVRON_UP.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.CHEVRON_UP
                                                            .color,
                                                        size: 22,
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        visible:
                                          _vm.configAccordion["company-details"]
                                            .isVisible,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "content-card-collapsible inside",
                                        },
                                        [
                                          _c(
                                            "b-overlay",
                                            {
                                              attrs: {
                                                show: _vm.isBusyCompany,
                                                rounded: "lg",
                                                opacity: "0.75",
                                                "spinner-variant": "primary",
                                              },
                                              on: {
                                                "update:show": function (
                                                  $event
                                                ) {
                                                  _vm.isBusyCompany = $event
                                                },
                                              },
                                            },
                                            [
                                              _vm.suppliers.length > 0
                                                ? _c(
                                                    "b-row",
                                                    { staticClass: "mt-1" },
                                                    _vm._l(
                                                      _vm.suppliers,
                                                      function (item, i) {
                                                        return _c(
                                                          "b-col",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "d-flex flex-row ml-2",
                                                            class: `${
                                                              i <
                                                              _vm.suppliers
                                                                .length -
                                                                1
                                                                ? "mb-2"
                                                                : ""
                                                            }`,
                                                            attrs: {
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "fieldset",
                                                              {
                                                                class: `${
                                                                  _vm.$screen
                                                                    .width <=
                                                                  576
                                                                    ? "card-inside"
                                                                    : "scheduler-border"
                                                                }`,
                                                                staticStyle: {
                                                                  width: "97%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-row",
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "pt-3 px-0",
                                                                        attrs: {
                                                                          sm: "5",
                                                                          md: "5",
                                                                          lg: "5",
                                                                          xl: "5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "company-form",
                                                                          {
                                                                            staticClass:
                                                                              "p-0 m-0",
                                                                            attrs:
                                                                              {
                                                                                item: item,
                                                                                index:
                                                                                  i,
                                                                              },
                                                                            on: {
                                                                              "finance-company-form:updated":
                                                                                _vm.onLineCompanyChanged,
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "pt-3",
                                                                        staticStyle:
                                                                          {
                                                                            "border-left":
                                                                              "0.125rem solid rgba(226, 230, 235, 0.85)",
                                                                          },
                                                                        attrs: {
                                                                          sm: "7",
                                                                          md: "7",
                                                                          lg: "7",
                                                                          xl: "7",
                                                                        },
                                                                      },
                                                                      [
                                                                        item
                                                                          .supplierBankAccounts
                                                                          .length >
                                                                        0
                                                                          ? _c(
                                                                              "div",
                                                                              _vm._l(
                                                                                item.supplierBankAccounts,
                                                                                function (
                                                                                  bankItem,
                                                                                  j
                                                                                ) {
                                                                                  return _c(
                                                                                    "b-row",
                                                                                    {
                                                                                      key: j,
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-col",
                                                                                        {
                                                                                          staticClass:
                                                                                            "p-0 m-0",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "bank-form",
                                                                                            {
                                                                                              staticClass:
                                                                                                "p-0 m-0",
                                                                                              attrs:
                                                                                                {
                                                                                                  item: bankItem,
                                                                                                  index:
                                                                                                    j,
                                                                                                  supplier:
                                                                                                    {
                                                                                                      id: +item.id,
                                                                                                      index:
                                                                                                        i,
                                                                                                    },
                                                                                                },
                                                                                              on: {
                                                                                                "supplier-bank-form:removed":
                                                                                                  _vm.removeBank,
                                                                                                "finance-bank-form:updated":
                                                                                                  _vm.onLineBankChanged,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "b-row",
                                                                              [
                                                                                _c(
                                                                                  "b-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-center py-2 text-empty-data",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                        _vm._s(
                                                                                          _vm.FormMSG(
                                                                                            11,
                                                                                            "No data found"
                                                                                          )
                                                                                        ) +
                                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                        _c(
                                                                          "b-row",
                                                                          {
                                                                            staticClass:
                                                                              "pb-3",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-col",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex justify-content-end",
                                                                                attrs:
                                                                                  {
                                                                                    cols: "12",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-button",
                                                                                  {
                                                                                    staticClass:
                                                                                      "w-200-px d-flex justify-content-center align-items-center",
                                                                                    attrs:
                                                                                      {
                                                                                        size: "sm",
                                                                                        variant:
                                                                                          "outline-dark",
                                                                                        block:
                                                                                          "",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addNewBank(
                                                                                            i
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      _vm.getLucideIcon(
                                                                                        "PlusCircle"
                                                                                      ),
                                                                                      {
                                                                                        tag: "component",
                                                                                        attrs:
                                                                                          {
                                                                                            size: 18,
                                                                                            "stroke-width": 2,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "px-2",
                                                                                        staticStyle:
                                                                                          {
                                                                                            "margin-bottom":
                                                                                              "-2px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                            _vm._s(
                                                                                              _vm.FormMSG(
                                                                                                18,
                                                                                                "Add new bank"
                                                                                              )
                                                                                            ) +
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center ml-2",
                                                                staticStyle: {
                                                                  width: "3%",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 btn bg-transparent border-0",
                                                                    attrs: {
                                                                      variant:
                                                                        "danger",
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeSupplier(
                                                                            item,
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "MinusCircle"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .ICONS
                                                                              .TRASH
                                                                              .color,
                                                                          "stroke-width": 3,
                                                                          size: 20,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-center py-2 text-empty-data",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                11,
                                                                "No data found"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                              _c(
                                                "b-row",
                                                { staticClass: "py-3" },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-content-end",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "w-250-px d-flex justify-content-center align-items-center",
                                                          attrs: {
                                                            size: "md",
                                                            variant:
                                                              "outline-primary",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.addNewCompany,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "PlusCircle"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                size: 18,
                                                                "stroke-width": 2,
                                                              },
                                                            }
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-2",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "-2px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    17,
                                                                    "Add new company"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { staticClass: "ml-3 px-2" },
                                                [
                                                  _c(
                                                    "fieldset",
                                                    {
                                                      staticClass:
                                                        "w-100 pt-4 pb-3 d-flex justify-content-center align-items-center",
                                                      class: `${
                                                        _vm.$screen.width <= 576
                                                          ? "card-inside"
                                                          : "scheduler-border"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "w-200-px d-flex justify-content-center align-items-center",
                                                          staticStyle: {
                                                            "margin-top":
                                                              "-8px",
                                                          },
                                                          attrs: {
                                                            type: "submit",
                                                            size: "md",
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.watingSubmitForm,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-center align-items-center",
                                                            },
                                                            [
                                                              _c("b-spinner", {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.watingSubmitForm,
                                                                    expression:
                                                                      "watingSubmitForm",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-2",
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "1px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.FormMSG(
                                                                        2,
                                                                        "Save"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "card-collapsible px-0 mt-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickHeader(
                                            "tax-codes"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "title w-90" },
                                        [
                                          _c(_vm.getLucideIcon("FileType2"), {
                                            tag: "component",
                                            attrs: {
                                              color: _vm.ICONS.ALARM_PLUS.color,
                                              size: 22,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "fz-1-35 ml-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(4, "Tax codes")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cursor-pointer d-flex justify-content-end w-10",
                                        },
                                        [
                                          !_vm.configAccordion["tax-codes"]
                                            .isVisible
                                            ? _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHEVRON_DOWN.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.CHEVRON_DOWN
                                                        .color,
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                          _vm.configAccordion["tax-codes"]
                                            .isVisible
                                            ? _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHEVRON_UP.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.CHEVRON_UP
                                                        .color,
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        visible:
                                          _vm.configAccordion["tax-codes"]
                                            .isVisible,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "content-card-collapsible inside",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sub-title-layout pb-3",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(5, "Categories")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _vm.taxes.length > 0
                                            ? _c(
                                                "b-row",
                                                _vm._l(
                                                  _vm.taxes,
                                                  function (tax, i) {
                                                    return _c(
                                                      "b-col",
                                                      {
                                                        key: i,
                                                        staticClass: "mb-2",
                                                        attrs: { sm: "2" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "wrap-flag",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "flag",
                                                                class: `flag-${tax.flagId}`,
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.7rem",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateTax(
                                                                        tax
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      tax.name
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            tax.flagId > 0
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "cursor-pointer has-btn-delete",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeFlag(
                                                                            tax.id,
                                                                            i,
                                                                            0
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        "MinusCircle"
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            "#EA4E2C",
                                                                          size: 18,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center pb-2 text-empty-data",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        11,
                                                        "No data found"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "card-collapsible px-0 mt-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickHeader("flags")
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "title w-90" },
                                        [
                                          _c(_vm.getLucideIcon("Flag"), {
                                            tag: "component",
                                            attrs: {
                                              color: _vm.ICONS.ALARM_PLUS.color,
                                              size: 22,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "fz-1-35 ml-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(3, "Custom Flags")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cursor-pointer d-flex justify-content-end w-10",
                                        },
                                        [
                                          !_vm.configAccordion["flags"]
                                            .isVisible
                                            ? _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHEVRON_DOWN.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.CHEVRON_DOWN
                                                        .color,
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                          _vm.configAccordion["flags"].isVisible
                                            ? _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHEVRON_UP.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.CHEVRON_UP
                                                        .color,
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        visible:
                                          _vm.configAccordion["flags"]
                                            .isVisible,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "content-card-collapsible inside",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sub-title-layout pb-3",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(5, "Categories")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _vm.flags.length > 0
                                            ? _c(
                                                "b-row",
                                                _vm._l(
                                                  _vm.flags,
                                                  function (flag, i) {
                                                    return _c(
                                                      "b-col",
                                                      {
                                                        key: i,
                                                        staticClass: "mb-2",
                                                        attrs: { sm: "2" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "wrap-flag",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "flag",
                                                                class: `flag-${flag.flagId}`,
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.7rem",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateFlag(
                                                                        flag
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      flag.name
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "cursor-pointer has-btn-delete",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeFlag(
                                                                        flag.id,
                                                                        i,
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    "MinusCircle"
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        "#EA4E2C",
                                                                      size: 18,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center pb-2 text-empty-data",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        11,
                                                        "No data found"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "b-row",
                                            { staticClass: "pb-2" },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-end",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "w-200-px d-flex justify-content-center align-items-center",
                                                      attrs: {
                                                        size: "sm",
                                                        variant:
                                                          "outline-primary",
                                                        block: "",
                                                      },
                                                      on: {
                                                        click: _vm.addNewFlag,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "PlusCircle"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            size: 18,
                                                            "stroke-width": 2,
                                                          },
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "px-2",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "-2px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  6,
                                                                  "Add new category"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "card-collapsible px-0 mt-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "header-card-collapsible inside d-flex flex-row align-items-center bgc-grey-light cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClickHeader(
                                            "per-diem"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "title w-90" },
                                        [
                                          _c(_vm.getLucideIcon("Scroll"), {
                                            tag: "component",
                                            attrs: {
                                              color: _vm.ICONS.ALARM_PLUS.color,
                                              size: 22,
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "fz-1-35 ml-3" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    301,
                                                    "Custom Allowances"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "cursor-pointer d-flex justify-content-end w-10",
                                        },
                                        [
                                          !_vm.configAccordion["per-diem"]
                                            .isVisible
                                            ? _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHEVRON_DOWN.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.CHEVRON_DOWN
                                                        .color,
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                          _vm.configAccordion["per-diem"]
                                            .isVisible
                                            ? _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.CHEVRON_UP.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.CHEVRON_UP
                                                        .color,
                                                    size: 22,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        visible:
                                          _vm.configAccordion["per-diem"]
                                            .isVisible,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "content-card-collapsible inside",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sub-title-layout pb-3",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(5, "Categories")
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _vm._l(
                                                _vm.perDiems,
                                                function (flag, i) {
                                                  return _c(
                                                    "b-col",
                                                    {
                                                      key: i,
                                                      staticClass: "mb-2",
                                                      attrs: { sm: "2" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "wrap-flag",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flag",
                                                              class: `flag-${flag.flagId}`,
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.7rem",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateCustoms(
                                                                      flag,
                                                                      false
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    flag.name
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._l(
                                                _vm.allowances,
                                                function (flag, i) {
                                                  return _c(
                                                    "b-col",
                                                    {
                                                      key: i,
                                                      staticClass: "mb-2",
                                                      attrs: { sm: "2" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "wrap-flag",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flag",
                                                              class: `flag-${flag.flagId}`,
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.7rem",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateCustoms(
                                                                      flag,
                                                                      true
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    flag.name
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("finance-per-diem-form", {
          attrs: {
            title: _vm.titlePerDiemFormModal,
            action: _vm.actionPerDiemFormModal,
            "for-allowances": _vm.forAllowances,
            item: _vm.customs,
          },
          on: {
            "finance-flag-form-modal:refreshed": function ($event) {
              return _vm.onRefreshed(1)
            },
            "finance-per-diem-modal:closed": _vm.onPerDiemModalClosed,
            "finance-flag-form-modal:reloaded": _vm.onFlagFormModalReloaded,
          },
          model: {
            value: _vm.isPerDiemFormOpen,
            callback: function ($$v) {
              _vm.isPerDiemFormOpen = $$v
            },
            expression: "isPerDiemFormOpen",
          },
        }),
        _c("flag-form-modal", {
          attrs: {
            title: _vm.titleFlagFormModal,
            action: _vm.actionFlagFormModal,
            "is-data-exist": _vm.isInitialDataCustomFlagExist,
            item: _vm.flag,
          },
          on: {
            "finance-flag-form-modal:refreshed": function ($event) {
              return _vm.onRefreshed(1)
            },
            "finance-flag-form-modal:closed": _vm.onFlagFormModalClosed,
            "finance-flag-form-modal:reloaded": _vm.onFlagFormModalReloaded,
          },
          model: {
            value: _vm.isFlagFormOpen,
            callback: function ($$v) {
              _vm.isFlagFormOpen = $$v
            },
            expression: "isFlagFormOpen",
          },
        }),
        _c("tax-form-modal", {
          attrs: {
            title: _vm.titleTaxFormModal,
            action: _vm.actionTaxFormModal,
            "is-data-exist": _vm.isInitialDataTaxExist,
            item: _vm.tax,
          },
          on: {
            "finance-tax-form-modal:refreshed": function ($event) {
              return _vm.onRefreshed(0)
            },
            "finance-tax-form-modal:closed": _vm.onTaxFormModalClosed,
            "finance-tax-form-modal:reloaded": _vm.onTaxFormModalReloaded,
          },
          model: {
            value: _vm.isTaxFormOpen,
            callback: function ($$v) {
              _vm.isTaxFormOpen = $$v
            },
            expression: "isTaxFormOpen",
          },
        }),
        _c(
          "b-modal",
          {
            staticClass: "modal-warning",
            attrs: {
              "header-class": "header-class-modal-doc-package",
              "header-bg-variant": "warning",
              title: this.FormMSG(21, "Warning"),
              "ok-variant": "warning",
              "ok-only": "",
              "modal-class": "mui-animation",
              fade: false,
            },
            on: {
              ok: function ($event) {
                _vm.warningMissingItemModal = false
              },
            },
            model: {
              value: _vm.warningMissingItemModal,
              callback: function ($$v) {
                _vm.warningMissingItemModal = $$v
              },
              expression: "warningMissingItemModal",
            },
          },
          [
            _c("div", { staticStyle: { "font-size": "1rem" } }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.FormMSG(
                      22,
                      "Please fill the field(s) required (coloring red) or remove one or all of them."
                    )
                  ) +
                  "\n\t\t\t"
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }