<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		:title="title"
		size="xl"
		centered
		cancel-variant="light"
		@hidden="emitEventClose"
		no-close-on-backdrop
		no-close-on-esc
		modal-class="mui-animation"
		:fade="false"
	>
		<div class="container-layout">
			<per-diem-form
				ref="form"
				:action="action"
				:item="flag"
				:for-allowances="forAllowances"
				:display-add-btn="false"
				@finance-flag-form:waiting="onFlagFormLoaded"
				@finance-flag-form:close-modal="onFlagFormForceClosed"
				@finance-flag-form:reload="onFlagFormReloaded"
			/>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="md"
					variant="custom-outline-gray"
					style="margin-top: 5px"
					class="w-138-px mr-3"
					:disabled="watingSubmitForm"
					@click="emitEventClose"
					block
				>
					{{ FormMSG(100, 'Close') }}
				</b-button>
				<b-button size="md" variant="primary" class="w-138-px" :disabled="watingSubmitForm" @click="dispatchEvent" block>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div :class="`${watingSubmitForm ? 'pl-2' : ''}`" style="margin-top: 1px">{{ FormMSG(200, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import PerDiemForm from '@/components/Finance/PerDiemForm';
import _ from 'lodash';
import { ACTION } from '@/shared/constants';

export default {
	name: 'FinancePerDiemForm',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		forAllowances: {
			type: Boolean,
			required: false,
			default: false
		},
		title: {
			type: String,
			required: true,
			default: ''
		},
		action: {
			type: String,
			required: true,
			default: ''
		},
		item: {
			type: Object,
			required: false,
			default: () => {}
		}
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	components: {
		PerDiemForm
	},
	data() {
		return {
			watingSubmitForm: false,
			flag: null
		};
	},
	methods: {
		emitEventClose() {
			this.$emit('finance-per-diem-modal:closed');
		},
		dispatchEvent(e) {
			e.preventDefault();
			this.$refs['form'].$refs['submit'].click();
		},
		onFlagFormLoaded(payload) {
			this.watingSubmitForm = payload;
		},
		onFlagFormForceClosed(payload) {
			if (!_.isNil(payload)) {
				if (_.isString(payload)) {
					this.createToastForMobile(this.FormMSG(3, 'Success'), payload, '', 'success');
					this.$emit('finance-flag-form-modal:refreshed');
				}
				this.$emit('finance-per-diem-modal:closed');
			}
		},
		onFlagFormReloaded(payload) {
			this.$emit('finance-flag-form-modal:reloaded', payload);
		}
	},
	watch: {
		item: {
			handler(value) {
				if (this.action === ACTION.UPDATE) {
					if (!_.isNil(value)) {
						this.flag = _.cloneDeep(value);
					}
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped></style>
