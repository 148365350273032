var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "premiseModal",
        "header-class": "header-class-modal-doc-package",
        title: _vm.rendTitle,
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        size: "xl",
        "cancel-variant": "custom-outline-gray",
        "ok-title": _vm.FormMSG(300, "Save"),
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { hidden: _vm.hideModal, ok: _vm.handleOk, cancel: _vm.hideModal },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("div", [
        _c("div", [
          _c("div", [
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "text-right" }, [
                _c("label", [
                  _vm._v(_vm._s(_vm.FormMSG(51, "Premise no.")) + " "),
                  _c("span", { staticClass: "idSpan" }, [
                    _vm._v("P" + _vm._s(_vm.premise.id)),
                  ]),
                ]),
              ]),
              _c(
                "fieldset",
                { staticClass: "scheduler-border" },
                [
                  _c("legend", { staticClass: "scheduler-border" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(200, "General")) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: 4 } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(3, "Name"),
                                "label-for": "name",
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "form-control",
                                attrs: { id: "name", type: "text" },
                                model: {
                                  value: _vm.premise.setName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.premise, "setName", $$v)
                                  },
                                  expression: "premise.setName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(6, "Address"),
                                "label-for": "address",
                              },
                            },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        staticClass: "br-none",
                                        attrs: {
                                          value: _vm.formatAddress(
                                            _vm.premise.address5
                                          ),
                                          placeholder: _vm.FormMSG(
                                            459,
                                            "No selected address..."
                                          ),
                                        },
                                      }),
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-white input-group-text-bg-white",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn-transparent text-color-aqua-velvet",
                                              on: {
                                                click:
                                                  _vm.handleClickAddAddress,
                                              },
                                            },
                                            [
                                              _vm.premise.addressId5 == 0
                                                ? _c(
                                                    _vm.getLucideIcon(
                                                      "PlusCircle"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 20 },
                                                    }
                                                  )
                                                : _c(
                                                    _vm.getLucideIcon("Edit"),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 20 },
                                                    }
                                                  ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "fieldset",
                { staticClass: "scheduler-border" },
                [
                  _c("legend", { staticClass: "scheduler-border" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(201, "Area")) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(10, "Type of premises"),
                                "label-for": "type",
                              },
                            },
                            [
                              _c("treeselect", {
                                attrs: {
                                  options: _vm.premiseType,
                                  id: "type",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.premise.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.premise, "type", $$v)
                                  },
                                  expression: "premise.type",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(30, "Area premises"),
                                "label-for": "area",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "number", id: "area" },
                                    model: {
                                      value: _vm.premise.filmingArea,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "filmingArea",
                                          $$v
                                        )
                                      },
                                      expression: "premise.filmingArea",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "littleBorderRad",
                                          attrs: { disabled: "" },
                                        },
                                        [_vm._v(" m2 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "occupation" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            11,
                                            "Total no. of days occupation"
                                          )
                                        ) +
                                        "  \n\t\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                    _c("info-card", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              40,
                                              "Total number of days that the property will be occupied. Make sure to not include holidays or weekends (if applicable)."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "occupation", type: "number" },
                                    model: {
                                      value: _vm.premise.totNbOfDaysOccupation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "totNbOfDaysOccupation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "premise.totNbOfDaysOccupation",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "littleBorderRad",
                                          attrs: { disabled: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.FormMSG(49, "days")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "d" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            12,
                                            "Total no. of man days"
                                          )
                                        ) +
                                        "  \n\t\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                    _c(
                                      "info-card",
                                      { attrs: { position: "top-left" } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                41,
                                                "Total number of days the property will be occupied times the average number of people expected to be in the space."
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "days", type: "number" },
                                    model: {
                                      value: _vm.premise.totNbOfManDays,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "totNbOfManDays",
                                          $$v
                                        )
                                      },
                                      expression: "premise.totNbOfManDays",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "littleBorderRad",
                                          attrs: { disabled: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.FormMSG(49, "days")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  17,
                                  "No. of air conditionners"
                                ),
                                "label-for": "airCond",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "airCond", type: "text" },
                                    model: {
                                      value: _vm.premise.nbOfAirConditioners,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "nbOfAirConditioners",
                                          $$v
                                        )
                                      },
                                      expression: "premise.nbOfAirConditioners",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "littleBorderRad",
                                          attrs: { disabled: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.FormMSG(50, "total")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "fieldset",
                {
                  staticClass: "my-0 py-0 pb-0 mt-4",
                  class: `${
                    _vm.$screen.width <= 576
                      ? "card-inside"
                      : "scheduler-border"
                  }`,
                },
                [
                  _c(
                    "legend",
                    {
                      staticClass: "text-color-rhapsody-in-blue-2",
                      class: `${
                        _vm.$screen.width <= 576
                          ? "card-inside"
                          : "scheduler-border"
                      }`,
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(698, "Consumption")) +
                          "\n\t\t\t\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "pt-3 pb-4" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "pb-3" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "pb-2",
                                      attrs: { for: "type" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(
                                                  631,
                                                  "Energy saving plan"
                                                )
                                              ) +
                                              " \n\t\t\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c("info-card", [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    643,
                                                    "Have you implemented the Ademe energy saving plan?  Information can be found here:"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "blank",
                                                  href: "https://agirpourlatransition.ademe.fr/entreprises/sobriete-energetique-entreprises",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      644,
                                                      "Ademe wabsite"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.energySavingPlanMenu,
                                    },
                                    model: {
                                      value: _vm.premise.energySavingPlan,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.premise,
                                          "energySavingPlan",
                                          $$v
                                        )
                                      },
                                      expression: "premise.energySavingPlan",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", md: "8", lg: "8", xl: "8" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "4",
                                        lg: "4",
                                        xl: "4",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        18,
                                                        "Green energy supplier"
                                                      )
                                                    ) +
                                                    "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                                _c("info-card", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          45,
                                                          "Does this premise receive energy from a green supplier?  A green energy supplier generates its electricity from natural resources like solar and wind."
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("b-form-radio-group", {
                                            staticClass:
                                              "radioStyleCheckBox pl-2 pj-cb pt-1",
                                            attrs: {
                                              options: _vm.yesNoOptions,
                                              name: "radio-inliness",
                                              size: "lg",
                                            },
                                            model: {
                                              value:
                                                _vm.premise.greenEnergySupplier,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.premise,
                                                  "greenEnergySupplier",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "premise.greenEnergySupplier",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "4",
                                        lg: "4",
                                        xl: "4",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        19,
                                                        "Recycled paper"
                                                      )
                                                    ) +
                                                    "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                                _c("info-card", [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          46,
                                                          "Does this premise used recycled printer paper?"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("b-form-radio-group", {
                                            staticClass:
                                              "radioStyleCheckBox pl-2 pj-cb pt-1",
                                            attrs: {
                                              options: _vm.yesNoOptions,
                                              name: "radio-inlines",
                                              size: "lg",
                                            },
                                            model: {
                                              value: _vm.premise.recycledPaper,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.premise,
                                                  "recycledPaper",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "premise.recycledPaper",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "4",
                                        lg: "4",
                                        xl: "4",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        13,
                                                        "Sorted waste"
                                                      )
                                                    ) +
                                                    "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                                _c(
                                                  "info-card",
                                                  {
                                                    attrs: {
                                                      position: "top-left",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            47,
                                                            "Does this premise sort it’s waste for recycling purposes?"
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c("b-form-radio-group", {
                                            staticClass:
                                              "radioStyleCheckBox pl-2 pj-cb pt-1",
                                            attrs: {
                                              options: _vm.yesNoOptions,
                                              name: "radio-inline",
                                              size: "lg",
                                            },
                                            model: {
                                              value: _vm.premise.sortedWaste,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.premise,
                                                  "sortedWaste",
                                                  $$v
                                                )
                                              },
                                              expression: "premise.sortedWaste",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("view-consumption-energy", {
                        attrs: { location: _vm.premise },
                        on: {
                          "view-consumption-energy:add-edit":
                            _vm.handleClickeAddEditConsumption,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "fieldset",
                { staticClass: "scheduler-border" },
                [
                  _c("legend", { staticClass: "scheduler-border" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(20, "Computer Equipment")) +
                        "\n\t\t\t\t\t\t"
                    ),
                  ]),
                  _vm.premise.computerEquipments.length > 0
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: 4 } }, [
                            _c("b", [_vm._v(_vm._s(_vm.FormMSG(21, "Type")))]),
                          ]),
                          _c("b-col", { attrs: { cols: 5 } }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.FormMSG(22, "Description"))),
                            ]),
                          ]),
                          _c("b-col", { attrs: { cols: 2 } }, [
                            _c("b", [
                              _vm._v(_vm._s(_vm.FormMSG(23, "Quantity"))),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.premise.computerEquipments.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "pl-3",
                          staticStyle: { "margin-top": "-6px" },
                        },
                        _vm._l(
                          _vm.premise.computerEquipments,
                          function (equipment, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("premises-equipment", {
                                  attrs: { equipment: equipment },
                                  on: {
                                    delete: _vm.handleDelete,
                                    change: _vm.handleChangeForEqp,
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      )
                    : _c("div", { staticClass: "mt-2 w-100" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(404, "No computer equipment found")
                            ) +
                            "\n\t\t\t\t\t\t"
                        ),
                      ]),
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mb-3",
                        class:
                          _vm.premise.computerEquipments.length > 0
                            ? "float-right"
                            : "float-left",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "cursor-pointer",
                            on: { click: _vm.handleAddNewEqp },
                          },
                          [
                            _c("PlusCircle", {
                              staticClass: "mr-2",
                              attrs: { size: 16, color: "#00A09C" },
                            }),
                            _c("b", [
                              _vm._v(_vm._s(_vm.FormMSG(24, "Add equipment"))),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(547, "Add address"),
            size: "xl",
            centered: "",
            "no-close-on-backdrop": "",
            "ok-variant": "success",
            "ok-title": _vm.FormMSG(300, "Save"),
            "cancel-title": _vm.FormMSG(301, "Cancel"),
            "modal-class": "mui-animation",
            fade: false,
          },
          on: { ok: _vm.handleUpdateAddress },
          model: {
            value: _vm.openOffsetLocationModal,
            callback: function ($$v) {
              _vm.openOffsetLocationModal = $$v
            },
            expression: "openOffsetLocationModal",
          },
        },
        [
          _c("AddressForm", {
            attrs: { "edit-data": _vm.premise.address5 },
            on: { change: _vm.handleAddressFormChange },
          }),
        ],
        1
      ),
      _c("location-entry-carbon-dialog", {
        attrs: {
          open: _vm.showLocationEntryCarbon,
          location: _vm.premise,
          carbons: _vm.carbons,
          "is-premise": "",
        },
        on: {
          "location-entry-carbon:submit-success":
            _vm.handleLocationEntryCarbonSubmitSuccess,
          "location-entry-carbon:close": _vm.handleLocationEntryCarbonClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }