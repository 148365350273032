<style>
.vue-treeselect__control {
	height: 31px !important;
}
.input-group {
	height: 32px !important;
	overflow: hidden !important;
}
.idSpan {
	background: #cad0d74a;
	padding: 10px;
	border-radius: 7px;
}
.radioStyleCheckBox label {
	padding-top: 2px !important;
}
.fz-12 {
	font-size: 12px !important;
}
.scheduler-border {
	color: rgba(9, 54, 90, 0.74) !important;
	margin: 3px -8px !important;
	padding: 0 7px;
}
.littleBorderRad {
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
}
.modal-header .close {
	font-size: 37px;
}
label {
	margin-bottom: 0 !important;
}
</style>
<template>
	<b-modal
		id="premiseModal"
		header-class="header-class-modal-doc-package"
		:title="rendTitle"
		no-close-on-esc
		no-close-on-backdrop
		v-model="isOpen"
		size="xl"
		cancel-variant="custom-outline-gray"
		@hidden="hideModal"
		@ok="handleOk"
		@cancel="hideModal"
		:ok-title="FormMSG(300, 'Save')"
		modal-class="mui-animation"
		:fade="false"
	>
		<div>
			<div>
				<div>
					<div class="modal-body">
						<div class="text-right">
							<label
								>{{ FormMSG(51, 'Premise no.') }} <span class="idSpan">P{{ premise.id }}</span>
							</label>
						</div>
						<fieldset class="scheduler-border">
							<legend class="scheduler-border">
								{{ FormMSG(200, 'General') }}
							</legend>
							<b-row>
								<b-col :cols="4">
									<b-form-group :label="FormMSG(3, 'Name')" label-for="name">
										<b-form-input class="form-control" v-model="premise.setName" id="name" type="text"></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group :label="FormMSG(6, 'Address')" label-for="address">
										<b-form-group class="mb-0">
											<b-input-group>
												<b-form-input
													class="br-none"
													:value="formatAddress(premise.address5)"
													:placeholder="FormMSG(459, 'No selected address...')"
												/>
												<b-input-group-text class="bg-color-white input-group-text-bg-white">
													<button class="btn-transparent text-color-aqua-velvet" @click="handleClickAddAddress">
														<component v-if="premise.addressId5 == 0" :is="getLucideIcon('PlusCircle')" :size="20" />
														<component v-else :is="getLucideIcon('Edit')" :size="20" />
													</button>
												</b-input-group-text>
											</b-input-group>
										</b-form-group>
									</b-form-group>
								</b-col>
							</b-row>
						</fieldset>
						<fieldset class="scheduler-border">
							<legend class="scheduler-border">
								{{ FormMSG(201, 'Area') }}
							</legend>

							<b-row>
								<b-col sm="12" md="3" lg="3" xl="3">
									<b-form-group :label="FormMSG(10, 'Type of premises')" label-for="type">
										<treeselect :options="premiseType" v-model="premise.type" id="type" :clearable="false" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col sm="12" md="3" lg="3" xl="3">
									<b-form-group :label="FormMSG(30, 'Area premises')" label-for="area">
										<b-input-group>
											<b-form-input type="number" id="area" v-model="premise.filmingArea"></b-form-input>
											<b-input-group-append>
												<b-button disabled class="littleBorderRad"> m2 </b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="3" lg="3" xl="3">
									<b-form-group>
										<label for="occupation">
											<div style="display: flex; align-items: center">
												{{ FormMSG(11, 'Total no. of days occupation') }} &nbsp;
												<info-card>
													{{
														FormMSG(
															40,
															'Total number of days that the property will be occupied. Make sure to not include holidays or weekends (if applicable).'
														)
													}}
												</info-card>
											</div>
										</label>
										<b-input-group>
											<b-form-input id="occupation" type="number" v-model="premise.totNbOfDaysOccupation"></b-form-input>
											<b-input-group-append>
												<b-button disabled class="littleBorderRad"> {{ FormMSG(49, 'days') }} </b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="3" lg="3" xl="3">
									<b-form-group>
										<label for="d">
											<div style="display: flex; align-items: center">
												{{ FormMSG(12, 'Total no. of man days') }} &nbsp;
												<info-card position="top-left">
													{{
														FormMSG(
															41,
															'Total number of days the property will be occupied times the average number of people expected to be in the space.'
														)
													}}
												</info-card>
											</div>
										</label>
										<b-input-group>
											<b-form-input id="days" type="number" v-model="premise.totNbOfManDays"></b-form-input>
											<b-input-group-append>
												<b-button disabled class="littleBorderRad"> {{ FormMSG(49, 'days') }} </b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col sm="12" md="3" lg="3" xl="3">
									<b-form-group :label="FormMSG(17, 'No. of air conditionners')" label-for="airCond">
										<b-input-group>
											<b-form-input v-model="premise.nbOfAirConditioners" id="airCond" type="text"></b-form-input>
											<b-input-group-append>
												<b-button disabled class="littleBorderRad"> {{ FormMSG(50, 'total') }} </b-button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</fieldset>
						<fieldset class="my-0 py-0 pb-0 mt-4" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
								{{ FormMSG(698, 'Consumption') }}
							</legend>
							<div class="pt-3 pb-4">
								<b-row class="pb-3">
									<b-col sm="12" md="4" lg="4" xl="4">
										<b-form-group>
											<label for="type" class="pb-2">
												<div style="display: flex; align-items: center">
													{{ FormMSG(631, 'Energy saving plan') }}&nbsp;
													<info-card>
														{{ FormMSG(643, 'Have you implemented the Ademe energy saving plan?  Information can be found here:') }}
														<a
															target="blank"
															href="https://agirpourlatransition.ademe.fr/entreprises/sobriete-energetique-entreprises"
															>{{ FormMSG(644, 'Ademe wabsite') }}</a
														>
													</info-card>
												</div>
											</label>
											<b-form-select v-model="premise.energySavingPlan" :options="energySavingPlanMenu" />
										</b-form-group>
									</b-col>
									<b-col sm="12" md="8" lg="8" xl="8">
										<b-row>
											<b-col sm="12" md="4" lg="4" xl="4">
												<b-form-group>
													<label>
														<div style="display: flex; align-items: center">
															{{ FormMSG(18, 'Green energy supplier') }} &nbsp;
															<info-card>
																{{
																	FormMSG(
																		45,
																		'Does this premise receive energy from a green supplier?  A green energy supplier generates its electricity from natural resources like solar and wind.'
																	)
																}}
															</info-card>
														</div>
													</label>
													<b-form-radio-group
														v-model="premise.greenEnergySupplier"
														:options="yesNoOptions"
														class="radioStyleCheckBox pl-2 pj-cb pt-1"
														name="radio-inliness"
														size="lg"
													/>
												</b-form-group>
											</b-col>
											<b-col sm="12" md="4" lg="4" xl="4">
												<b-form-group>
													<label>
														<div style="display: flex">
															{{ FormMSG(19, 'Recycled paper') }} &nbsp;
															<info-card>
																{{ FormMSG(46, 'Does this premise used recycled printer paper?') }}
															</info-card>
														</div>
													</label>
													<b-form-radio-group
														v-model="premise.recycledPaper"
														:options="yesNoOptions"
														class="radioStyleCheckBox pl-2 pj-cb pt-1"
														name="radio-inlines"
														size="lg"
													/>
												</b-form-group>
											</b-col>
											<b-col sm="12" md="4" lg="4" xl="4">
												<b-form-group>
													<label>
														<div style="display: flex">
															{{ FormMSG(13, 'Sorted waste') }} &nbsp;
															<info-card position="top-left">
																{{ FormMSG(47, 'Does this premise sort it’s waste for recycling purposes?') }}
															</info-card>
														</div>
													</label>
													<b-form-radio-group
														v-model="premise.sortedWaste"
														:options="yesNoOptions"
														class="radioStyleCheckBox pl-2 pj-cb pt-1"
														name="radio-inline"
														size="lg"
													/>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<view-consumption-energy :location="premise" @view-consumption-energy:add-edit="handleClickeAddEditConsumption" />
							</div>
						</fieldset>
						<fieldset class="scheduler-border">
							<legend class="scheduler-border">
								{{ FormMSG(20, 'Computer Equipment') }}
							</legend>
							<b-row v-if="premise.computerEquipments.length > 0">
								<b-col :cols="4"
									><b>{{ FormMSG(21, 'Type') }}</b></b-col
								>
								<b-col :cols="5"
									><b>{{ FormMSG(22, 'Description') }}</b></b-col
								>
								<b-col :cols="2"
									><b>{{ FormMSG(23, 'Quantity') }}</b></b-col
								>
							</b-row>
							<div v-if="premise.computerEquipments.length > 0" style="margin-top: -6px" class="pl-3">
								<div v-for="(equipment, index) in premise.computerEquipments" :key="index">
									<premises-equipment @delete="handleDelete" @change="handleChangeForEqp" :equipment="equipment" />
								</div>
							</div>
							<div v-else class="mt-2 w-100">
								{{ FormMSG(404, 'No computer equipment found') }}
							</div>
							<!-- <div v-else>No equipment yet</div> -->
							<div class="mt-3">
								<div class="mb-3" :class="premise.computerEquipments.length > 0 ? 'float-right' : 'float-left'">
									<div class="cursor-pointer" @click="handleAddNewEqp">
										<PlusCircle :size="16" color="#00A09C" class="mr-2" /> <b>{{ FormMSG(24, 'Add equipment') }}</b>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
			<!-- <b-col>
					<b-tooltip target="delete" placement="top">{{ FormMSG(5, 'Delete') }}</b-tooltip>
					<div @click="handleDelete(premise.id)" class="cursor-pointer" id="delete">
						<MinusCircle color="tomato" :size="30" />
					</div>P
				</b-col> -->
		</div>
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="modal"
			v-model="openOffsetLocationModal"
			:title="FormMSG(547, 'Add address')"
			size="xl"
			centered
			no-close-on-backdrop
			ok-variant="success"
			@ok="handleUpdateAddress"
			:ok-title="FormMSG(300, 'Save')"
			:cancel-title="FormMSG(301, 'Cancel')"
			modal-class="mui-animation"
			:fade="false"
		>
			<AddressForm :edit-data="premise.address5" @change="handleAddressFormChange" />
		</b-modal>

		<location-entry-carbon-dialog
			:open="showLocationEntryCarbon"
			:location="premise"
			:carbons="carbons"
			is-premise
			@location-entry-carbon:submit-success="handleLocationEntryCarbonSubmitSuccess"
			@location-entry-carbon:close="handleLocationEntryCarbonClose"
		/>
	</b-modal>
</template>
<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { MinusCircle, PlusCircle } from 'lucide-vue';
import Treeselect from '@riophae/vue-treeselect';
import premisesEquipment from './premesisEquipment.vue';
import InfoCard from './infoCard.vue';
import { store } from '@/store';
import AddressForm from '@/components/Forms/Address';
import { addressFormatter } from '../../shared/fomaters';
import { newAddress, updateAddress } from '@/cruds/address.crud';
import { createAndUpdateComputerEquipment, delComputerEquipment } from '../../cruds/premises.crud';
import CoTwoClap from '../../modules/carbonclap/components/green/CoTwoClap.vue';
import { INIT_DATA_CARBON } from '@/shared/constants';
import _ from 'lodash';
import ViewConsumptionEnergy from '../../components/LocationService/ViewConsumptionEnergy.vue';
import LocationEntryCarbonDialog from '../../modules/carbonclap/components/green/LocationEntryCarbonDialog.vue';
import { getLocation } from '@/cruds/locations.crud';

export default {
	name: 'premiseModal',
	components: { LocationEntryCarbonDialog, ViewConsumptionEnergy, CoTwoClap, MinusCircle, AddressForm, premisesEquipment, PlusCircle, InfoCard, Treeselect },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		showPremiseModal: {
			type: Boolean,
			required: true
		},
		isUpdate: {
			type: Boolean,
			required: false,
			default: false
		},
		premiseData: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			erreur: '',
			openOffsetLocationModal: false,
			equipmentToUpdate: [],
			idToDelete: [],
			update: false,
			premise: {
				id: 0,
				setName: '',
				addressId5: 0,
				address5: {
					city: '',
					country: '',
					box: '',
					street: '',
					number: '',
					state: '',
					other: '',
					zip: ''
				},
				kind: 0,
				type: 0,
				createdAt: new Date(),
				filmingArea: 0,
				totNbOfDaysOccupation: 0,
				totNbOfManDays: 0,
				energySavingPlan: 0,
				nbOfAirConditioners: 0,
				greenEnergySupplier: 0,
				recycledPaper: 0,
				sortedWaste: 0,
				computerEquipments: []
			},
			carbonData: _.cloneDeep(INIT_DATA_CARBON),

			showLocationEntryCarbon: false,
			carbons: []
		};
	},
	watch: {
		isUpdate: {
			handler(bool) {
				this.update = bool;
			},
			immediate: true,
			deep: true
		},
		premiseData: {
			handler(val) {
				this.premise = val;
				this.carbonData = val.carbon;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		yesNoOptions() {
			const options = [
				{ text: this.FormMSG(15, 'No'), value: false },
				{ text: this.FormMSG(14, 'Yes'), value: true }
			];
			return options;
		},
		energySavingPlanMenu() {
			return this.FormMenu(1359);
		},
		premiseType() {
			const menu = this.FormMenu(1400).map((menu) => {
				return {
					id: menu.value,
					label: menu.text
				};
			});
			return menu;
		},
		rendTitle() {
			if (this.update) {
				return this.FormMSG(2000, 'Update Premises');
			} else {
				return this.FormMSG(1, 'New Premises');
			}
		},
		isOpen: {
			get: function () {
				return this.showPremiseModal;
			},
			set: function (val) {
				return val;
			}
		}
	},

	methods: {
		handleClickeAddEditConsumption() {
			this.carbons = this.premise.carbon;
			this.showLocationEntryCarbon = true;
		},
		async getLocation() {
			this.premise = await getLocation(+this.premiseData.id);
		},
		async handleLocationEntryCarbonSubmitSuccess({ carbons }) {
			const oldPremise = _.cloneDeep(this.premise);
			await this.getLocation();

			this.premise.setName = oldPremise.setName;
			this.premise.address5 = oldPremise.address5;
			this.premise.addressId5 = oldPremise.addressId5;
			this.premise.type = oldPremise.type;
			this.premise.filmingArea = oldPremise.filmingArea;
			this.premise.totNbOfDaysOccupation = oldPremise.totNbOfDaysOccupation;
			this.premise.totNbOfManDays = oldPremise.totNbOfManDays;
			this.premise.nbOfAirConditioners = oldPremise.nbOfAirConditioners;
			this.premise.computerEquipments = oldPremise.computerEquipments;
			this.premise.energySavingPlan = oldPremise.energySavingPlan;
			this.premise.greenEnergySupplier = oldPremise.greenEnergySupplier;
			this.premise.recycledPaper = oldPremise.recycledPaper;
			this.premise.sortedWaste = oldPremise.sortedWaste;
		},
		handleLocationEntryCarbonClose() {
			this.carbons = [];
			this.showLocationEntryCarbon = false;
		},
		handleCoTwoClapChange({ carbonData }) {
			this.carbonData = carbonData;
		},
		async handleDelete(id) {
			this.idToDelete.push(parseInt(id, 10));
			this.premise.computerEquipments = this.premise.computerEquipments.filter((equipment) => {
				return equipment.id != id;
			});
		},
		handleChangeForEqp(val) {
			const index = this.equipmentToUpdate.findIndex((equipment) => equipment.id === val.id);
			if (index !== -1) {
				this.equipmentToUpdate.splice(index, 1, val);
			} else {
				this.equipmentToUpdate.push(val);
			}

			this.premise.computerEquipments = this.premise.computerEquipments.map((equipment) => {
				if (equipment.id === val.id) {
					equipment = val;
				}
				return equipment;
			});
		},
		async handleAddNewEqp() {
			await createAndUpdateComputerEquipment(0, { locationId: parseInt(this.premise.id, 10) }).then((res) => {
				this.premise.computerEquipments.push(res);
			});
		},
		async handleClickAddAddress() {
			if (this.premise.addressId5 == 0) {
				await newAddress().then((res) => {
					this.premise.addressId5 = res.id;
					delete res.id;
					this.premise.address5 = res;
					this.premise.address5.countryKey = store.state.myProject.legalEntities[0].address.countryKey;
				});
			}
			this.openOffsetLocationModal = true;
		},
		hideModal() {
			this.$emit('close', this.premise.id);
		},
		// async handleDelete(id) {
		// 	this.$emit('premise:Delete', id);
		// },
		formatAddress(payload) {
			return addressFormatter(payload);
		},
		handleAddressFormChange(payload) {
			this.premise.address5 = {
				...this.premise.address5,
				...payload
			};
		},
		async handleUpdateAddress() {
			await updateAddress(this.premise.addressId5, { ...this.premise.address5 });
		},
		async handleOk() {
			let payload = {};
			payload = {
				...this.premise,
				id: parseInt(this.premise.id, 10),
				addressId5: parseInt(this.premise.addressId5, 10),
				filmingArea: parseInt(this.premise.filmingArea, 10),
				totNbOfDaysOccupation: parseInt(this.premise.totNbOfDaysOccupation, 10),
				totNbOfManDays: parseInt(this.premise.totNbOfManDays, 10),
				nbOfAirConditioners: parseInt(this.premise.nbOfAirConditioners, 10),
				carbon: {
					...this.carbonData,
					carbonId: +this.premise.carbon.id
				}
			};
			if (this.idToDelete.length > 0) {
				for (const id of this.idToDelete) {
					await delComputerEquipment(parseInt(id, 10)).catch((err) => {
						console.log(err);
					});
				}
			}
			if (this.equipmentToUpdate.length > 0) {
				for (const equipment of this.equipmentToUpdate) {
					await createAndUpdateComputerEquipment(parseInt(equipment.id, 10), equipment);
				}
			}
			this.$emit('premise:Update', payload);
		}
	}
};
</script>
